var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{staticClass:"col-12 col-md-6"},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("İletişim Form Listesi")])]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","items-per-page-select":"","items-per-page":_vm.itemPerPage},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',{staticClass:"c-avatar"},[_c('img',{staticClass:"c-avatar-img",attrs:{"src":item.avatar.url,"alt":""}})])])}},{key:"teacher",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(_vm._s(item.teacher))]),_c('div',{staticClass:"small text-muted"})])}},{key:"daterange",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"clearfix"}),_c('div',[_vm._v(_vm._s(item.date))])])}},{key:"alltimes",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(_vm._s(item.title))])])}}])},[_c('td',{attrs:{"slot":"manage"},slot:"manage"},[_c('CCol',{staticClass:"text-center mb-3",attrs:{"col":"12"}},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":'/iletisim-formu-detaylari',"tag":"a","role":"button"}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }