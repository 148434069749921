<template>
  <CRow>
    <CCol class="col-12 col-md-6">
      <CCard>
        <CCardHeader>
          <strong>İletişim Form Listesi</strong>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              items-per-page-select
              :items-per-page="itemPerPage"
          >
            <td slot="avatar" class="text-center" slot-scope="{item}">
              <div class="c-avatar">
                <img :src="item.avatar.url" class="c-avatar-img" alt="">
              </div>
            </td>
            <td slot="teacher" slot-scope="{item}">
              <div>{{ item.teacher }}</div>
              <div class="small text-muted">

              </div>
            </td>
            <td slot="daterange" slot-scope="{item}">
              <div class="clearfix">
              </div>
              <div>{{ item.date }}</div>
            </td>
            <td
                slot="alltimes"
                slot-scope="{item}"
                class="text-center"
            >
              <div>{{ item.title }}</div>
            </td>
            <td slot="manage">
              <CCol col="12" class="text-center mb-3">
                <router-link :to="'/iletisim-formu-detaylari'" tag="a" role="button" class="btn btn-success">
                  <CIcon name="cil-pencil"/>
                </router-link>
              </CCol>
            </td>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "ContactForms",
  components: {},
  data() {
    return {
      isDataLoading: false,
      currentPage: 1,
      itemPerPage: 50,
      tableItems: [
        {
          ID: '45',
          name: 'Adam Alister',
          date: '16.05.2021',
          title: 'Lorem ipsum',
        },
      ],
      tableFields: [
        {key: 'ID', label: 'ID'},
        {key: 'name', label: 'Adı Soyadı'},
        {key: 'date', label: 'Gönderme Tarihi', _classes: 'text-center'},
        {key: 'title', label: 'Konu Başlığı'},
        {key: 'manage', label: 'Yönet', _classes: 'text-center'},
      ]
    }
  },
  methods: {
    color(value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
  }
}
</script>

